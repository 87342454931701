.App {
  text-align: center;
}

body {
  background-color: #baffe9;
  color: black;
}

.navbar {
  font-size: 1em;
  color: black;
  display: flex;
  list-style: none;
  justify-content: right;
  align-items: center;
  margin-right: 5em;
  margin-top: 3em;
}

.Michael {
  font-size: 5em;
  color: black;
  display: flex;
  list-style: none;
  justify-content: left;
  align-items: center;
  margin-left: 3em;
  margin-top: 1.5em;
}

.Michael-Description {
  font-size: 3em;
  color: black;
  display: flex;
  list-style: none;
  justify-content: left;
  align-items: center;
  margin-left: 5em;
  margin-top: 3em;
}

.Writing-Section {
  text-align: left;
  font-size: 1em;
  color: black;
  list-style: none;
  margin-left: 5em;
  margin-top: 3em;
  margin-right: 5em;
}

.Writing-Section-Title {
  text-align: left;
  margin-left: 2em;
  padding: 0.25em .5em;
  font-size: 2.5em;
  border: 1px solid black;
  width: fit-content;
  border-radius: 3px;
}

.Writing-Section-Icon {
  margin-top: .5em;
}

.Writing-Section-Title:hover {
  cursor: pointer;
}

.Writing-Section-Visible {
  text-align: left;
  display: block;
}

.Writing-Section-Hidden {
  text-align: left;
  display: none;
}

.Writing-Section-Description {
  font-size: 2em;
}

.Writing-Section-Articles h4 {
  font-size: 1.5em;
}

.Writing-Section-Article {
  font-size: 1.75em;
  line-height: 1.5em;
}

.Michael-Image {
  position: absolute;
  border-radius: 5px;
  right: 10vw;
  bottom: 10vh;
  width: 20%;
}

.Social-Links {
  font-size: 1em;
  color: black;
  list-style: none;
  margin-left: 5em;
  margin-top: 3em;
  position: absolute;
  bottom: 0;
  margin-bottom: 5em;
}

.Social-Link {
  width: 5em;
  height: 5em;
  color: black;
}

.Social-Flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

.Email-Box {
  position: relative;
  font-size: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  font-size: 1.5em;
}


.navbar li {
  padding: 0 1.5em;
}

.navbar li a {
  font-size: 2em;
  text-decoration: none;
  color: black;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Daytona', 'sans-serif';
  font-size: calc(10px + 2vmin);
  color: white;
}

.text {
  font-family: 'DM Sans', 'Arial', 'sans-serif';
  font-size: 1.5em;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

form h3 {
  margin-top: 0px !important;
}

form h3 input, form h3 textarea {
  border-radius: 5px;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: .15em;
}

form h3 textarea {
  height: 6em; 
  width: 20em;
}

form button {
  border-radius: 5px;
  font-size: .75em;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: .2em;
}

form button:hover {
  cursor: pointer;
  background-color: lightgray;
}

.error {
  color: red;
}

footer {
  font-size: 1em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5vh;
}